import * as React from 'react';
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Bamberg" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                1919 <br/> Bamberg <br/> Spiegelsaal der Harmonie
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Verfassung</li>
                                <li>Grund- und Menschenrechte</li>
                                <li>Parlamentarismus</li>
                                <li>Parteien</li>
                                <li>Wahlen</li>
                            </ul>
                            <p> Der Spiegelsaal der Bamberger Harmonie ist ein zentraler Ort für die moderne bayerische
                                Demokratie- und Verfassungsgeschichte.</p>
                            <p>Hier wurde 1919 die erste demokratische Verfassung des Freistaats Bayern ausgearbeitet
                                und verabschiedet.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Bamberg/bamberg_bild_0004.jpg"}
                                   data-glightbox="title: Sitzung des bayerischen Landtags im Spiegelsaal der Harmonie in Bamberg, 1919; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Sitzung des bayerischen Landtags im Spiegelsaal der Harmonie in Bamberg, 1919"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Mit der Verlegung des Landtags nach Bamberg wurde das Gebäude des Kultur- und
                                        Gesellschaftsvereins „Harmonie“ zum parlamentarischen Tagungsort. Zwischen 15.
                                        Mai und 14. August 1919 fanden hier insgesamt 23 Plenarsitzungen und 101
                                        Ausschusssitzungen statt.
                                    </p>
                                    <p className="copy">Abb. Bayerische Staatsbibliothek München, Bildarchiv
                                        hoff-5271</p>
                                </div>
                                <figcaption>
                                    Sitzung des bayerischen Landtags im Spiegelsaal der Harmonie in Bamberg, 1919
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Bamberg/bamberg_bild_0006.jpg"}
                                   data-glightbox="title: Sicherheitsvorkehrungen um das Harmoniegebäude am Schillerplatz in Bamberg, 1919; description: .custom-desc1_5; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Sicherheitsvorkehrungen um das Harmoniegebäude am Schillerplatz in Bamberg, 1919"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_5">
                                    <p>
                                        Anders als in München war in Bamberg die notwendige Sicherheit für Regierung und Landtag
                                        gegeben. Polizei, Militär und eine eigens gegründete Bürgerwehr gewährleisteten den
                                        Schutz.
                                    </p>
                                    <p className="copy">Abb. Stadtarchiv Bamberg, D 2072</p>
                                </div>
                                <figcaption>
                                    Sicherheitsvorkehrungen um das Harmoniegebäude am Schillerplatz in Bamberg, 1919
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
