import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";
import {Pages} from "../bamberg/facsimile";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <Container.Facsimile
                            pages={Pages['05_03']}
                            img="/images/Bamberg/bamberg_bild_0013.jpg"
                            alttext="Flugblatt des Bayerischen Vereins für Frauenstimmrecht zu den Wahlen im Januar 1919">
                            <figcaption>
                                Flugblatt des Bayerischen Vereins für Frauenstimmrecht zu den Wahlen im Januar 1919
                                <span className="description">
                                        Die Juristin Anita Augspurg engagierte sich ab 1895 für Frauenrechte. Mit der
                                Gründung des Deutschen Vereins für Frauenstimmrecht im Jahr 1902 setzte sie sich vor allem
                                für das Frauenwahlrecht ein. 1918 erhielten Frauen erstmals das aktive und passive Wahlrecht.
                                    </span>
                                <span className="copy">Abb. Stadtarchiv München, DE-1992-PL-04443</span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <Container.Facsimile
                            pages={Pages['05_04']}
                            img="/images/Bamberg/bamberg_bild_0012.jpg"
                            alttext="Flugblatt des Bamberger Tagblatts zur Verlegung des Regierungssitzes nach Bamberg, 8. April 1919">
                            <figcaption>
                                Flugblatt des Bamberger Tagblatts zur Verlegung des Regierungssitzes nach Bamberg, 8.
                                April
                                1919
                                <span className="description">
                                        Angesichts der unruhigen politischen Lage in München verlegte die Regierung
                                ihren Sitz am 7. April 1919 nach Bamberg. Die Stadt war wegen ihres ruhigen politischen
                                Klimas, der zuverlässigen Garnison und der guten Verkehrsverbindung (Eisenbahn, Flugplatz)
                                ein geeigneter Standort.
                                    </span>
                                <span className="copy">Abb. Stadtarchiv Bamberg, KS, C.b. 610</span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <Container.Facsimile
                        pages={Pages['05_02']}
                        img="/images/Bamberg/bamberg_bild_0014.jpg"
                        alttext="Aufruf Kurt Eisners und des Arbeiter-, Soldaten- und Bauernrats „An die Bevölkerung Münchens!“, 8. November 1918">
                        <figcaption>
                            Aufruf Kurt Eisners und des Arbeiter-, Soldaten- und Bauernrats „An die Bevölkerung
                            Münchens!“, 8. November 1918
                            <span className="description">
                                        Schon in der Proklamation des Freistaats kündigte Eisner in München eine
                                        Nationalversammlung mit Wahlrecht für alle mündigen Frauen und Männer an. Er
                                        wollte den Wahltermin aber hinauszögern, da er der Meinung war, dass sich das
                                        demokratische Bewusstsein in der Bevölkerung erst festigen müsse.
                                    </span>
                            <span className="copy">Münchner Neueste Nachrichten, 8. November 1918
                                        Abb. Bayerische Staatsbibliothek München, 2 H.un.app. 47 h-1918,10/12, Nr. 564
                                    </span>
                        </figcaption>
                    </Container.Facsimile>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Bamberg/bamberg_bild_0005.jpg"}
                           data-glightbox="title: Ausweiskontrolle durch die Bürgerwehr am Eingang des Bamberger Harmoniesaals, 1919; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Ausweiskontrolle durch die Bürgerwehr am Eingang des Bamberger Harmoniesaals, 1919"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                Die Gegend um das Harmoniegebäude war mit bewaffneten Truppen stark gesichert. Vor dem
                                Einlass zu den Landtagssitzungen galt eine strenge Ausweispflicht.
                            </p>
                            <p className="copy">Abb. Stadtarchiv Bamberg, D 1045 + 373 – 2</p>
                        </div>
                        <figcaption>
                            Ausweiskontrolle durch die Bürgerwehr am Eingang des Bamberger Harmoniesaals, 1919
                            <span className="description">
                                Die Gegend um das Harmoniegebäude war mit bewaffneten Truppen stark gesichert. Vor dem
                                Einlass zu den Landtagssitzungen galt eine strenge Ausweispflicht.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
