import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                Zu den Vorschriften der Verfassung des Deutschen Reiches, im Zweiten Hauptteile, Vierten Abschnitt,
                Artikeln 142 bis 149 wird bestimmt:
            </p>
            <p>
                <strong>§ 20. </strong>Die Freiheit der Kunst, der Wissenschaft und ihrer Lehre wird gewährleistet und
                kann nur durch Gesetz und nur zur Wahrung der öffentlichen Ordnung, Sicherheit, Gesundheit oder
                Sittlichkeit beschränkt werden.
            </p>
            <p>
                <strong>§ 21. </strong>(1) Die Regelung und Förderung des öffentlichen Erziehungs-, Unterrichts- und
                Bildungswesens sowie die Genehmigung und Beaufsichtigung der privaten Unterrichts- und Erziehungsanstalten
                sind Angelegenheiten des Staates. Die öffentlichen Volksschulen sind grundsätzlich Staatsanstalten.
            </p>
            <p>
                (2) Die Erziehungsberechtigten und die Personen, denen Schulpflichtige durch besonderen Auftrag anvertraut
                sind, sind verpflichtet, diese während der Dauer der gesetzlichen Schulpflicht zum Schulbesuche anzuhalten.
            </p>
            <h4>5. Abschnitt<br/><br/>Selbstverwaltung, Stiftungen</h4>
            <p>
                <strong>§ 22. </strong>(1) Den bürgerlichen Gemeinden und den Gemeindeverbänden wird das
                Selbstverwaltungsrecht gewährleistet. Sie verwalten nach Maßgabe der Gesetze ihre eigenen und die ihnen
                vom Staat übertragenen Angelegenheiten. Sie haben das Recht, ihren Bedarf durch öffentliche Abgaben im
                Rahmen der Gesetze zu decken. Neue Aufgaben und Lasten können ihnen nur auf Grund Gesetzes zugewiesen werden.
            </p>
            <p>
                (2) Der Staat überwacht die Erfüllung der Pflichten und die Gesetzmäßigkeit ihrer Verwaltung.
            </p>
            <p>
                (3) Der Staat schützt die Behörden der Gemeinden und Gemeindeverbände bei Durchführung ihrer Aufgaben.
            </p>
            <p>
                (4) Gegen Überschreitungen der Grenzen, die den Aufsichtsbehörden durch das Gesetz entzogen sind, haben
                die Gemeinden und Gemeindeverbände Anspruch auf verwaltungsgerichtlichen Schutz.
            </p>
            <p>
                (5) Das Vermögen der Gemeinden und Gemeindeverbände kann unter keinem Vorwande zum Staatsvermögen gezogen
                werden.
            </p>
            <p>
                <strong>§ 23. </strong>Die Wahlen für die Vertretungskörper der Gemeinden und Gemeindeverbände erfolgen
                nach den Grundsätzen des Landtagswahlrechtes. Sie werden durch Gesetz geregelt.
            </p>
            <p>
                <strong>§ 24. </strong>(1) Die Bildung von berufsständischen Vertretungen zur Wahrnehmung der
                wirtschaftlichen und sozialen Aufgaben aller schaffenden Kreise des Volkes erfolgt durch Gesetz.
            </p>
            <p>
                (2) Diese Vertretungen sind berechtigt, innerhalb ihres Wirkungsbereiches in Gegenständen der
                Gesetzgebung Anträge an den Landtag zu bringen. In den übrigen Gegenständen ihres Wirkungsbereiches
                können sie an die Verwaltungsbehörden des Staates und der Gemeinden Anträge richten, über welche
                Entscheidung getroffen und den Antragstellern mitgeteilt werden muß.
            </p>
            <p>
                <strong>§ 25. </strong>(1) Das gesamte Vermögen der öffentlichen Stiftungen und die stiftungsweise
                Verwendung seiner Erträgnisse unterstehen dem besonderen Schutze des Staates. Stiftungsvermögen darf
                unter keinen Vorwande dem Staatsvermögen einverleibt werden. Verwaltung und Ausrichtung der öffentlichen
                Stiftungen werden staatlich beaufsichtigt.
            </p>
            <p>
                (2) Ein besonderes Gesetz über das Stiftungswesen bleibt vorbehalten.
            </p>
            <h4>6. Abschnitt<br/><br/>Landtag</h4>
            <p>
                a) Wahl, Mitgliedschaft, Geschäftsgang, Auflösung
            </p>
            <p>
                <strong>§ 26. </strong>(1) Der Landtag wird durch allgemeine, gleiche, geheime und unmittelbare Wahl
                nach dem Grundsatze des Verhältniswahlrechts gebildet.
            </p>
            <p>
                (2) Wählbar sind nur wahlberechtigte bayerische Staatsbürger, welche das fünfundzwanzigste Lebensjahr
                vollendet haben. Wahlrecht und Wahlverfahren werden im übrigen durch besonderes Gesetz geregelt. Auf je
                40000 Landeseinwohner nach der letzten Volkszählung soll ein Abgeordneter treffen. Beträgt der Recht
                mehr als 20000, so ist ein weiterer Abgeordneter zu wählen.
            </p>
            <p>
                <strong>§ 27. </strong>Der Landtag wird auf vier Jahre gewählt. Vor Ablauf dieser Zeit ist er neu zu wählen.
            </p>
            <p>
                <strong>§ 28. </strong>(1) Der Landtag bestimmt durch Geschäftsordnung seine Einrichtungen, seinen
                Geschäftsgang, seine Disziplin und Gliederung. Er wählt für seine Dauer aus seiner Mitte einen Vorstand,
                der aus einem Präsidenten, seinen Vertretern und den Schriftführern besteht.
            </p>
            <p>
                (2) Die Verhandlungen des Landtages sind öffentlich. Die Geschäftsordnung kann Ausnahmen zulassen. Die
                Minister und ihre Bevollmächtigten können für Mitteilungen oder Verhandlungen, die Geheimhaltung erfordern,
                den Ausschluß der Öffentlichkeit beantragen.
            </p>
            <p>
                (3) Über Gesetzentwürfe findet mindestens eine doppelte Lesung in der Vollversammlung statt.
            </p>
            <p>
                (4) Die Entwürfe der Gesetze und des Haushaltsplanes sind vor der ersten Lesung allgemein zugänglich
                zu machen.
            </p>
            <p>
                <strong>§ 29. </strong>Bestellung, Besoldung und Entlassung sowie Dienstverhältnis und
                Dienstbeaufsichtigung der Landtagsbeamten werden durch die Geschäftsordnung des Landtages geregelt,
                soweit nicht durch Gesetz anderes bestimmt ist.
            </p>
            <p>
                <strong>§ 30. </strong>(1) Der Landtag tritt alljährlich mindestens einmal zu einer ordentlichen Tagung
                zusammen. Zu einer außerordentlichen Tagung versammelt er sich, wenn seine Einberufung vom Vorstand oder
                Gesamtministerium beschlossen oder von fünfzig Mitgliedern oder einem Fünftel der stimmberechtigten
                Staatsbürger beantragt wird.
            </p>
        </>
    )
}

export default Page;
