import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                b) Aufgaben, Rechte
            </p>
            <p>
                <strong>§ 44. </strong>Der Landtag hat das Recht der Gesetzgebung und übt es nach den Bestimmungen der
                Verfassung aus.
            </p>
            <p>
                <strong>§ 45. </strong>Ehe der Landtag Vorlagen der Volksentscheidung unterbreitet, hat er hierüber nach
                §§ 76 und 77 Beschluß zu fassen.
            </p>
            <p>
                <strong>§ 46. </strong>Verordnungen, welche die Einrichtung oder Veränderung von Behörden oder Stellen
                treffen, sind dem Landtage zur Genehmigung vorzulegen. In dringenden Fällen kann das Gesamtministerium
                solche Verordnungen unter Vorbehalt der nachträglichen Bestätigung des Landtages erlassen.
            </p>
            <p>
                <strong>§ 47. </strong>(1) Ohne Genehmigung des Landtages kann keine neue Anleihe, welche die Erhöhung
                des bisherigen Schuldenstandes des Staates an Kapital oder Verzinsung bewirkt, aufgenommen oder eine
                Bürgschaft zu Lasten des Staates eingegangen werden.
            </p>
            <p>
                (2) Ohne Genehmigung des Landtages kann weder der von ihm beschlossene Staatsschuldentilgungsplan
                geändert noch eine zur Schuldentilgung bestimmtes Gefäll zu einem anderen Zwecke verwendet werden.
            </p>
            <p>
                (3) Das Grundstockvermögen des Staates darf in seinem Wertbestande durch Veräußerungen nicht verringert
                werden. Soweit nicht durch Gesetz das Ministerium zu Veräußerungen von Grundstockvermögen ermächtigt wird,
                können solche nur mit Genehmigung des Landtages erfolgen. Der Erlös solcher Veräußerungen ist zu
                Neuerwerbungen für das Grundstockvermögen zu verwenden.
            </p>
            <p>
                <strong>§ 48. </strong>Der Landtag beschließt über den vom Gesamtministerium alljährlich vorzulegenden
                Haushaltsplan.
            </p>
            <p>
                <strong>§ 49. </strong>Der Landtag beschließt über die alljährlich vom Gesamtministerium abzulegende
                Rechnung des Staates samt Vermögens- und Schuldennachweis.
            </p>
            <p>
                <strong>§ 50. </strong>Der Landtag genehmigt die Staatsverträge. Sie sind, wenn sie Rechtssätze enthalten,
                hinsichtlich ihres Rechtsinhaltes als Gesetze zu beschließen.
            </p>
            <p>
                <strong>§ 51. </strong>(1) Der Landtag beschließt durch Gesetz über Straferlasse, die sich auf alle
                bestraften Personen oder auf besondere Gruppen beziehen.
            </p>
            <p>
                (2) Straferlasse für einzelne Personen stehen dem Gesamtministerium oder nach dessen Beschlusse den
                einzelnen Ministerien zu.
            </p>
            <p>
                <strong>§ 52. </strong>(1) Durch beauftragte Mitglieder kann der Landtag nach vorausgegangener
                Verständigung des Ministeriums von den Einrichtungen und Unternehmungen der Staatsverwaltung, insbesondere
                der Staatsschuldenverwaltung Einsicht nehmen.
            </p>
            <p>
                (2) Der Landtag muß auf Antrag von einem Fünftel seiner Mitglieder Ausschüsse zur Untersuchung von
                Tatsachen ernennen. Sie haben das Recht, staatliche und gemeindliche Behörden zur Durchführung ihrer
                Aufgabe in Anspruch zu nehmen. Die Ausschüsse können die Untersuchungen auch selbst durchführen. Auf die
                Erhebungen der Ausschüsse und der von ihnen ersuchten Behörden finden die Vorschriften der
                Strafprozeßordnung sinngemäße Anwendung. Die gesetzlichen Vorschriften über die Wahrung des Brief-, Post-,
                Telegraphen- und Fernsprechgeheimnisses werden hierdurch nicht berührt. Die Geschäftsordnung des
                Landtages regelt das Verfahren.
            </p>
            <p>
                <strong>§ 53. </strong>Der Landtag hat das Recht, das Gesamtministerium oder einzelne Minister zur
                Verantwortung zu ziehen. Dies kann durch parlamentarische oder gerichtliche Mittel geschehen.
            </p>
            <p>
                <strong>§ 54. </strong>Das ordentliche parlamentarische Mittel ist die Rechenschaftsforderung. Der zur
                Rechenschaft geforderte Minister hat sich im Landtage persönlich zu verantworten.
            </p>
            <p>
                <strong>§ 55. </strong>(1) Das außerordentliche parlamentarische Mittel ist die Kundgabe des Mißtrauens,
                die sich gegen das Gesamtministerium oder gegen einzelne Minister richten kann.
            </p>
            <p>
                (2) Der Antrag auf eine solche Kundgebung bedarf der Unterstützung von mindestens dreißig Abgeordneten,
                ist dem Ministerpräsidenten sofort schriftlich mitzuteilen und innerhalb fünf Tagen zur Beratung zu bringen.
            </p>
            <p>
                (3) Die Minister müssen zur Verhandlung über die gegen sie gerichteten Mißtrauensanträge persönlich
                erscheinen, wenn sie nicht vorher zurücktreten. Erscheinen sie nicht, so kann in ihrer Abwesenheit beraten
                und beschlossen werden.
            </p>
            <p>
                (4) Der Beschluß ist gültig, wenn mindestens die Mehrheit der gesetzlichen Mitgliederzahl zustimmt.
                Er ist eingehend zu begründen.
            </p>
            <p>
                <strong>§ 56. </strong>. (1) Das gerichtliche Mittel der Geltendmachung der Ministerverantwortlichkeit
                ist die Anklage vor dem Staatsgerichtshofe. Die Anklage kann nur darauf gerichtet sein, daß ein Minister
                in Ausübung seines Amtes durch Handlungen oder Unterlassungen die Verfassung oder ein Gesetz vorsätzlich
                oder grobfahrlässig verletzt hat.
            </p>
            <p>
                (2) Der Antrag auf Erhebung der Anklage muß von mindestens fünfzig Abgeordneten unterzeichnet sein. Der
                Beschluß bedarf der Zustimmung der für Verfassungsänderungen vorgeschriebenen Mehrheit.
            </p>
            <p>
                (3) Wird der Angeklagte für schuldig befunden, so kann nur auf Entfernung aus dem Amte erkannt werden.
                War er schon vor dem Urteile zurückgetreten, so ist eine Entscheidung nur über die Schuldfrage zu treffen.
            </p>
            <p>
                (4) Die Anklage wird durch Vertagung oder Auflösung des Landtages oder Ablauf der Landtagsdauer nicht berührt.
            </p>
        </>
    )
}

export default Page;
