import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock3Beige = (key) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Der bayerische Landtag 1919</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Bamberg/bamberg_bild_0008.jpg"}
                           data-glightbox="title: Landtagspräsidium und Regierungsbank im Spiegelsaal der Bamberger Harmonie, 1919 9; description: .custom-desc1_8; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Landtagspräsidium und Regierungsbank im Spiegelsaal der Bamberger Harmonie, 1919 "/>
                        </a>
                        <div className="glightbox-desc custom-desc1_8">
                            <p>
                                In der Bildmitte ist der Landtagspräsident Franz Schmitt zu sehen, Ministerpräsident
                                Johannes Hoffmann sitzt als Zweiter von rechts.
                            </p>
                            <p className="copy">Abb. Süddeutsche Zeitung Photo</p>
                        </div>
                        <figcaption>
                            Landtagspräsidium und Regierungsbank im Spiegelsaal der Bamberger Harmonie, 1919
                            <span className="description">
                                In der Bildmitte ist der Landtagspräsident Franz Schmitt zu sehen, Ministerpräsident
                                Johannes Hoffmann sitzt als Zweiter von rechts.</span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Bamberg/Bayerische-Verfassung_von_1919.png"}
                           data-glightbox="title: Die bayerische Verfassung von 1919; description: .custom-desc3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Bamberg/Bayerische-Verfassung_von_1919.png" width={335} layout="fixed" placeholder="none" alt="Die bayerische Verfassung von 1919"/>
                        </a>
                        <div className="glightbox-desc custom-desc3">
                            <p>
                                Die bayerische Verfassung von 1919
                            </p>
                        </div>
                        <figcaption>
                            Die bayerische Verfassung von 1919
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Beige
