import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Johannes Hoffmann (Sozialdemokratische Partei Deutschlands)</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Bamberg/bamberg_bild_0010.jpg"}
                           data-glightbox="description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0010.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Johannes Hoffmann (1867–1930), 1919"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <h2>Johannes Hoffmann (1867–1930), 1919</h2>
                            <p>
                                „Der Staat hat […] sozial fühlende, sozial handelnde Menschen, denkende Staatsbürger und
                                tüchtige Arbeiter heranzubilden.“
                            </p>
                            <p className="copy">Abb. Bildarchiv Bayerischer Landtag, Foto: Adalbert Werner</p>
                        </div>
                        <figcaption>
                            Johannes Hoffmann (1867–1930), 1919
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                      <blockquote>
                          „Der Staat hat […] sozial fühlende, sozial handelnde Menschen, denkende Staatsbürger und
                          tüchtige Arbeiter heranzubilden.“
                      </blockquote>
                    <p>
                        Der aus der Pfalz stammende Johannes Hoffmann gehörte ab 1908 dem bayerischen Landtag an. 1918
                        wurde er in der Revolutionsregierung Minister für Unterricht und Kultus und Stellvertreter von
                        Kurt Eisner. Als früherer Volksschullehrer engagierte er sich besonders im Bereich der
                        Bildungspolitik und setzte sich für die Aufhebung der geistlichen Schulaufsicht ein. 1919 wurde
                        er zum Vorsitzenden des Ministerrats gewählt und trat entschieden für den Parlamentarismus ein.
                        Von Bamberg aus lenkte er die Niederschlagung der Münchner Räterepublik mit Unterstützung der
                        Reichswehr. Nach der Inkraftsetzung der Bamberger Verfassung im September 1919 war er der erste
                        durch freie Wahlen und Verfassung legitimierte Ministerpräsident Bayerns. 1920 legte er, im
                        Kontext des Kapp-Lüttwitz-Putschs unter Druck gesetzt, seine Ämter in Bayern nieder. Bis zu
                        seinem Tod war er Mitglied des Reichstags in Berlin.
                    </p>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock4White
