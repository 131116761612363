import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                (2) Der Präsident beruft, eröffnet und schließt den Landtag. Zu jeder ersten Tagung nach einer Neuwahl
                wird der Landtag von seinen drei ältesten Mitgliedern binnen siebzehn Tagen nach dem Tage der amtlichen
                Feststellung des Wahlergebnisses einberufen.
            </p>
            <p>
                (3) Der Landtag hat das Recht, seine Tagungen zu schließen. Er steht für die zwischen zwei Tagungen
                liegende Zeit einen Ausschuß ein und betraut ihn mit bestimmten Befugnissen. Zur Wahrnehmung der Rechte
                der Volksvertretung setzt der Landtag für die Zeit nach Ablauf seiner Dauer oder nach seiner Auflösung
                einen ständigen Ausschuß ein.
            </p>
            <p>
                (4) Begehrt mindestens ein Fünftel der stimmberechtigten Staatsbürger die Auflösung des Landtages, so
                ist eine Volksentscheidung hierüber anzuordnen. Die Abstimmung ist nur rechtswirksam, wenn an ihr
                mindestens die Hälfte der Stimmberechtigten teilgenommen und eine Mehrheit von mindestens Zweidrittel
                der abgegebenen Stimmen für die Auflösung sich ausgesprochen haben.
            </p>
            <p>
                (5) Wird die Auflösung des Landtages beschlossen, so ist sie durch seinen Präsidenten baldigst zu vollziehen.
            </p>
            <p>
                <strong>§ 31. </strong>Der Landtag kann jederzeit seine Auflösung beschließen. Zur Gültigkeit des
                Beschlusses ist die Anwesenheit von zwei Dritteln und die Zustimmung von mehr als der Hälfte der
                gesetzlichen Mitgliederzahl erforderlich.
            </p>
            <p>
                <strong>§ 32. </strong>Nach einer Auflösung des Landtages sind Neuwahlen so anzuordnen, daß die
                Einberufung binnen sechzig Tagen erfolgen kann.
            </p>
            <p>
                <strong>§ 33. </strong>Der Landtag prüft das Recht der Mitgliedschaft und entscheidet über die Gültigkeit
                der Wahlen. Er kann diese Aufgabe einem Gerichtshof übertragen.
            </p>
            <p>
                <strong>§ 34. </strong>Vorbehaltlich besonderer Vorschrift der Verfassung beschließt der Landtag mit
                Mehrheit der abgegebenen Stimmen und bei Anwesenheit von mehr als der Hälfte seiner Mitglieder.
            </p>
            <p>
                <strong>§ 35. </strong>(1) Die Mitglieder des Landtages sind Vertreter des gesamten Volkes und an
                Aufträge der Wähler nicht gebunden. Sie können ihre Rechte nur selbst ausüben.
            </p>
            <p>
                (2) Beamte, Angestellte und ständige Arbeiter des Staates, der bürgerlichen Gemeinden und der
                Gemeindeverbände sowie Heeresangehörige bedürfen keines Urlaubs zur Teilnahme an den Landtagsverhandlungen.
                Bewerben sie sich auf Grund eines nach gesetzlicher Vorschrift eingereichten Wahlvorschlages um einen
                Sitz im Landtage, so ist ihnen von der Einreichung des Wahlvorschlages an Urlaub zu gewähren.
            </p>
            <p>
                <strong>§ 36. </strong>Jeder Abgeordnete hat nach Maßgabe der Geschäftsordnung das Recht, Anträge zu stellen.
            </p>
            <p>
                <strong>§ 37. </strong>(1) Kein Abgeordneter darf zu irgend einer Zeit wegen einer Äußerung, die er in
                Ausübung seines Berufs getan hat, oder wegen seiner Abstimmung gerichtlich oder dienstlich verfolgt oder
                sonst außerhalb der Versammlung zur Verantwortung gezogen werden.
            </p>
            <p>
                (2) Hat ein Abgeordneter in dieser Eigenschaft von jemand eine Tatsache anvertraut erhalten oder jemand
                eine solche Tatsache anvertraut, so ist er berechtigt, über diese Person und Tatsachen das Zeugnis zu
                verweigern. Hinsichtlich der Beschlagnahme von Schriftstücken steht er den Personen gleich, die ein
                gesetzliches Recht haben, das Zeugnis zu verweigern.
            </p>
            <p>
                <strong>§ 38. </strong>Wahrheitsgetreue Berichte über Verhandlungen in den öffentlichen Sitzungen bleiben
                von jeder Verantwortung frei.
            </p>
            <p>
                <strong>§ 39. </strong>(1) Kein Abgeordneter darf während der Tagung ohne Genehmigung des Landtages
                verhaftet oder wegen einer mit Strafe bedrohten Handlung in Untersuchung gezogen werden, außer wenn er
                bei Ausübung der Tat oder im Laufe des nächsten Tages festgenommen worden ist.
            </p>
            <p>
                (2) Auf Verlangen des Landtages ist jede Haft eines Abgeordneten und jedes Strafverfahren gegen einen
                solchen für die Dauer der Tagung aufzuheben.
            </p>
            <p>
                (3) Während der Tagung darf kein Abgeordneter, solang er sich am Orte der Versammlung aufhält, ohne
                Genehmigung des Landtages an einem anderen Ort als Zeuge oder Sachverständiger vernommen werden.
            </p>
            <p>
                (4) Diese Bestimmungen finden auf die Mitglieder der Ausschüsse, die in der Zeit zwischen zwei Tagungen
                des Landtages versammelt sind, sinngemäße Anwendung.
            </p>
            <p>
                <strong>§ 40. </strong>Die Abgeordneten haben für die Dauer des Landtages Anspruch auf freie Benützung
                der staatlichen Verkehrsmittel und auf Entschädigung nach Gesetz.
            </p>
            <p>
                <strong>§ 41. </strong>(1) Die Mitgliedschaft endigt mit Ablauf der Landtagsdauer, Auflösung des Landtages,
                Ungültigkeitserklärung der Wahl, Wegfall der Wählbarkeit oder Verzicht. Der Verzicht ist unwiderruflich.
                Er ist dem Präsidenten schriftlich zu erklären.
            </p>
            <p>
                (2) Als Verzicht gilt auch die Annahme eines öffentlichen Dienstes in einem mit Bayern im Kriege
                befindlichen Staat.
            </p>
            <p>
                <strong>§ 42. </strong>Die Geschäftsordnung des Landtages hat dafür zu sorgen, daß in seinen Ausschüssen
                die Minderheiten verhältnismäßig vertreten sind.
            </p>
            <p>
                <strong>§ 43. </strong>Alle Verhandlungen und Beschlüsse der Vollversammlung des Landtages und des nach
                § 30 Abs. 3 gebildeten Ausschusses, der Haushaltsplan, die Staatsrechnungen, der Vermögens- und
                Schuldenstand des Staates sind im Wortlaut allgemein zugänglich zu machen.
            </p>
        </>
    )
}

export default Page;
