import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                <strong>§ 12. </strong>Die Staatsbürger sind nach Maßgabe der Gesetze zu den öffentlichen Ämtern
                zuzulassen, wenn sie ihre Befähigung hierfür nachweisen. Nur Tüchtigkeit und Würdigkeit sollen für
                Verleihung der Ämter maßgebend sein.
            </p>
            <h4>3. Abschnitt<br/><br/>Grundrechte</h4>
            <p>
                <strong>§ 13. </strong>Kein Staatsangehöriger kann aus dem Staatsgebiet ausgewiesen werden.
            </p>
            <p>
                <strong>§ 14. </strong>(1) Jeder Bayer hat das Recht, sich innerhalb des bayerischen Staatsgebietes an
                jedem Ort aufzuhalten und niederzulassen. Ausnahmen können nur auf Grund Gesetzes angeordnet werden.
            </p>
            <p>
                (2) Die Niederlassung darf bayerischen Staatsangehörigen an keinem Orte durch besondere Lasten erschwert
                werden.
            </p>
            <p>
                <strong>§ 15. </strong>(1) Alle Bayern sind vor dem Gesetze gleich.
            </p>
            <p>
                (2) Der bayerische Adel ist aufgehoben. Bayerische Staatsangehörige, die vor dem 28. März 1919
                Adelsbezeichnungen zu führen berechtigt waren, dürfen diese nur als Teil ihres Namens weiterführen.
                Adelsbezeichnungen werden nicht mehr verliehen. Den bayerischen Staatsangehörigen ist es verboten, die
                Verleihung des Adels eines anderen Staates anzunehmen.
            </p>
            <p>
                (3) Titel, die nicht ein Amt, einen Beruf oder einen akademischen Grad bezeichnen, werden nicht mehr
                verliehen.
            </p>
            <p>
                <strong>§ 16. </strong>Jedem Einwohner werden die Freiheit der Person und das Eigentum gewährleistet.
                Einschränkungen können nur nach Maßgabe der Gesetze angeordnet werden.
            </p>
            <h4>4. Abschnitt<br/><br/>Gewissensfreiheit, Religionsgesellschaften, Schule</h4>
            <p>
                <strong>§ 17. </strong>(1) Jedermann ist volle Glaubens- und Gewissensfreiheit gewährleistet.
            </p>
            <p>
                (2) Die Entscheidung über die Zugehörigkeit der Kinder zu einer Religionsgesellschaft steht bis zu deren
                vollendeten sechzehnten Lebensjahre den Erziehungsberechtigten zu. Bis zu diesem Zeitpunkte können die
                Eltern die Zugehörigkeit ihrer Kinder zu einer Religionsgesellschaft auch durch Vertrag regeln. Ein
                solcher Vertrag bedarf der gerichtlichen oder notariellen Beurkundung; er wird durch den Tod der Eltern
                nicht berührt. Ist ein Kind mit Zustimmung des Erziehungsberechtigten vor Vollendung des sechzehnten
                Lebensjahres durch einen Kultusakt einer Religionsgemeinschaft endgültig in diese aufgenommen worden,
                so kann hieran durch die Erziehungsberechtigten nichts mehr geändert werden. Von diesem Alter an hat das
                Kind selbst die Freiheit der Entschließung über sein Verbleiben in der Religionsgesellschaft.
            </p>
            <p>
                (3) Der Austritt aus einer Religionsgesellschaft kann mündlich oder schriftlich bei dem Standesamte des
                Wohnsitzes oder ständigen Aufenthaltsortes erklärt werden. Die schriftliche Erklärung bedarf der
                Beglaubigung durch eine öffentliche Behörde. Abs. 2 findet entsprechende Anwendung. Nichtigkeit und
                Anfechtbarkeit der Austrittserklärung sind nach den Vorschriften des bürgerlichen Rechtes zu beurteilen.
            </p>
            <p>
                (4) Neue freiwillige Leistungen des Staates, der bürgerlichen Gemeinden und Gemeindeverbände an eine
                Religionsgemeinschaft werden durch Zuschläge zu den Staatssteuern und Umlagen der Angehörigen dieser
                Religionsgemeinschaften aufgebracht.
            </p>
            <p>
                <strong>§ 18. </strong>(1) Die Vereinigung von Religionsgenossen zu gemeinsamer Hausandacht oder zu
                öffentlichen Kultushandlungen, zu Religionsgesellschaften, Religionsgemeinden oder geistlichen
                Gesellschaften ist innerhalb des Gesetzes freigegeben.
            </p>
            <p>
                (2) Bestehende Religionsgesellschaften, Religionsgemeinden oder geistliche Gesellschaften, dann ihre
                Anstalten. Stiftungen oder sonstigen Einrichtungen bleiben rechtsfähig, soweit sie es bisher waren.
                Neue können die Rechtsfähigkeit nach Maßgabe des geltenden Rechts erwerben. Ihr Eigentum und ihre anderen
                rechte sowie ihr Bekenntnisgepräge werden gewährleistet.
            </p>
            <p>
                (3) Religionsgesellschaften, Religionsgemeinden und geistlichen Gesellschaften wird die selbständige
                Ordnung und Verwaltung ihrer Angelegenheiten, den Religionsgesellschaften und Religionsgemeinden, welche
                die Rechtsstellung von Körperschaften des öffentlichen Rechtes besitzen, auch die Besteuerung ihrer
                Mitglieder auf Grund der bürgerlichen Steuerlisten innerhalb der Schranken des Gesetzes gewährleistet.
            </p>
            <p>
                (4) Bis zur Ablösung der Staatsleistungen gemäß Artikel 138 der Verfassung des Deutschen Reiches bleiben
                die auf Gesetz, Vertrag oder besonderem Rechtstitel beruhenden Staatsleistungen an die
                Religionsgesellschaften aufrechterhalten.
            </p>
            <p>
                (5) Bis zu dem gleichen Zeitpunkte dürfen Gebäude und Grundstücke des Staates, die derzeit irgendwelchen
                Kultuszwecken dienen, diesen gegen den Willen der Beteiligten nicht entzogen werden.
            </p>
            <p>
                <strong>§ 19. </strong>(1) Errichtung und Unterhaltung von Begräbnisplätzen obliegen den bürgerlichen
                Gemeinden. Das Gleiche gilt für die Errichtung und Unterhaltung von Bestattungsanstalten.
            </p>
            <p>
                (2) Die bürgerlichen Gemeinden sind zur Errichtung von Begräbnisplätzen und Bestattungsanstalten nur
                soweit verpflichtet, als die vorhandenen Begräbnisplätze und Bestattungsanstalten nicht ausreichen.
                Im übrigen bestimmen sich Errichtung und Unterhaltung nach dem öffentlichen Bedürfnis.
            </p>
            <p>
                (3) In den Friedhöfen, die nur für einzelne Religionsgesellschaften bestimmt sind, ist mangels eines
                gemeinschaftlichen Begräbnisplatzes die Beisetzung Andersgläubiger unter den für sie üblichen Formen
                und ohne räumliche Absonderung, erforderlichenfalls nach Anordnung der zuständigen Behörde zu gestatten.
            </p>
            <p>
                (4) Im übrigen bemißt sich der Simultangebrauch der Kirchen und Friedhöfe nach bisherigem Rechte, soweit
                nicht Abänderungen durch Gesetz getroffen werden.
            </p>
        </>
    )
}

export default Page;
