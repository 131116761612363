import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Transkription zu:<br/>Verfassung des Freistaats Bayern von 1919</h3>
            <p>
                Verfassungsurkunde des Freistaats Bayern<br/>
                Vom 14. August 1919 (GVBl. S. 531)
            </p>
            <p>
                Das bayerische Volk hat durch den am 12. Januar und 2. Februar 1919 gewählten Landtag dem Freistaate
                Bayern diese Verfassung gegeben:
            </p>
            <h4>1. Abschnitt<br/><br/>Staat, Staatsgebiet, Staatsgewalt</h4>
            <p>
                <strong>§ 1. </strong>(1) Bayern ist ein Freistaat und Mitglied des Deutschen Reiches.<br/>
                Die bisherigen Landesteile Bayerns in ihrem Gesamtbestande bilden das Staatsgebiet.<br/>
                (2) Die Landesfarben sind weiß und blau.
            </p>
            <p>
                <strong>§ 2. </strong>Die Staatsgewalt geht von der Gesamtheit des Volkes aus. Sie wird nach den
                Bestimmungen dieser Verfassung und der Verfassung des Deutschen Reiches unmittelbar durch die
                Staatsbürger und mittelbar durch die in dieser Verfassung eingesetzten Organe ausgeübt.
            </p>
            <p>
                <strong>§ 3. </strong>(1) Dem Landtage steht die Ausübung aller Rechte der Staatsgewalt zu, die nicht
                durch diese Verfassung oder die Verfassung des Deutschen Reiches der Staatsbürgerschaft, den Behörden
                oder den Verbänden der Selbstverwaltung vorbehalten sind.
            </p>
            <p>
                (2) Die dem Landtage zustehenden Rechte und Aufgaben sind unübertragbar, soweit diese Verfassung nichts
                anderes vorsieht.
            </p>
            <p>
                <strong>§ 4. </strong>Das Gesamtministerium ist die oberste vollziehende und leitende Behörde des Staates.
                Es wird von dem Landtage bestellt und ist dieser verantwortlich.
            </p>
            <p>
                <strong>§ 5. </strong>Die Rechtspflege wird durch unabhängige, nur den Gesetzen unterworfene Gerichte
                ausgeübt. Die Gerichte sind Staatsgerichte. Ihre Einrichtung erfolgt durch Gesetz.
            </p>
            <h4>2. Abschnitt<br/><br/>Staatsbürgerschaft</h4>
            <p>
                <strong>§ 6. </strong>Staatsbürger ist ohne Unterschied der Geburt, des Geschlechtes, des Glaubens und
                des Berufes jeder Angehörige des bayerischen Staates, welcher das zwanzigste Lebensjahr vollendet hat.
            </p>
            <p>
                <strong>§ 7. </strong>Der Staatsbürger übt sein Bürgerrecht aus durch Abstimmung<br/>
                1. bei Volksbegehren und Volksentscheidungen,<br/>
                2. bei Wahlen.
            </p>
            <p>
                <strong>§ 8. </strong>Jeder Staatsbürger hat das Recht, an den durch diese Verfassung vorgesehenen
                Abstimmungen und Wahlen teilzunehmen, wenn er seit mindestens sechs Monaten seinen Wohnsitz in Bayern hat.
                Das Stimm- und Wahlrecht wird, soweit nicht durch Gesetz Ausnahmen zugelassen sind, am Wohnsitz ausgeübt.
            </p>
            <p>
                <strong>§ 9. </strong>Von der Ausübung des Stimm- und Wahlrechtes sind ausgeschlossen:<br/>
                1. wer entmündigt, unter vorläufiger Vormundschaft oder wegen geistigen Gebrechens unter Pflegschaft
                gestellt ist,<br/>
                2. wer infolge strafgerichtlicher Verurteilung die bürgerlichen Ehrenrechte nicht besitzt.
            </p>
            <p>
                <strong>§ 10. </strong>(1) Volksbegehren können nur gerichtet werden:<br/>
                1. Auf Abänderung der Verfassung,<br/>
                2. auf Erlaß, Abänderung und Aufhebung von Gesetzen, soweit solche nicht von der Volksentscheidung
                ausgenommen sind (§ 77 Abs. 1),<br/>
                3. auf Einberufung oder Auflösung des Landtages (§ 30).
            </p>
            <p>
                (2) Volksbegehren sind an den Landtag und, wenn dieser nicht versammelt ist, an das Gesamtministerium
                zu richten. Sie sind vorbehaltlich der Vorschriften des § 30 Abs. 1 und 4 rechtswirksam bei einfachen
                Gesetzen, wenn sie von mindestens einem Zehntel, bei Verfassungsgesetzen, wenn sie von mindestens einem
                Fünftel der Staatsbürgerschaft gestellt werden.
            </p>
            <p>
                (3) Volksentscheidungen finden nur in den von dieser Verfassung vorgesehenen Fällen statt. Sie ist
                rechtswirksam bei einfachen Gesetzen, wenn mindestens ein Fünftel, bei Verfassungsgesetzen, wenn
                mindestens zwei Fünftel der stimmberechtigten Wahlberechtigten daran teilgenommen haben. Vorbehaltlich
                der Vorschrift des § 30 Abs. 4 entscheidet einfache Mehrheit, bei Verfassungsänderungen
                Zweidrittelmehrheit der abgegebenen gültigen Stimmen. Die Abstimmung ist allgemein, gleich, unmittelbar
                und geheim. Sie kann nur bejahend oder verneinend sein.
            </p>
            <p>
                (4) Das Verfahren bei Volksbegehren und Volksentscheidungen wird durch Gesetz geregelt.
            </p>
            <p>
                <strong>§ 11. </strong>(1) Jeder Staatsbürger hat in der Gemeinde seines Wohnsitzes das
                Gemeindebürgerrecht. Er kann es nur wirksam ausüben, wenn er seit mindestens sechs Monaten im
                Gemeindebezirk wohnt.
            </p>
            <p>
                (2) Die Ausübung des Wahlrechts in den Gemeinden darf nicht von der Entrichtung einer Gebühr abhängig
                gemacht werden.
            </p>
            <p>
                (3) Die Regelung der besonderen Rechte und Pflichten aus dem Gemeindeverbande bleibt der Gesetzgebung
                vorbehalten.
            </p>
        </>
    )
}

export default Page;
