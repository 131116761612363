import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Transkription zu:<br/>Aufruf Kurt Eisners und des Arbeiter-, Soldaten- und Bauernrats
                „An die Bevölkerung Münchens!“, 8. November 1918</h3>
            <p>
                Münchener Neueste Nachrichten, 8.11.1918:</p>
                <h3>An die Bevölkerung Münchens!</h3>
            <p>
                Das furchtbare Schicksal, das über das deutsche Volk hereingebrochen, hat zu einer elementaren Bewegung
                der Münchener Arbeiter und Soldaten geführt. Ein provisorischer Arbeiter-, Soldaten- und Bauernrat hat
                sich in der Nacht zum 8. November im Landtag konstituiert.
            </p>
            <p>
                Bayern ist fortan ein Freistaat.</p>
            <p>
                Eine Volksregierung, die vom Vertrauen der Masse getragen wird, soll unverzüglich eingesetzt werden.</p>
            <p>
                Eine konstituierende Nationalversammlung, zu der alle mündigen Männer und Frauen das Wahlrecht haben,
                wird so schnell wie möglich einberufen werden.</p>
            <p>
                Eine neue Zeit hebt an!</p>
            <p>
                Bayern will Deutschland für den Völkerbund rüsten.</p>
            <p>
                Die demokratische und soziale Republik Bayern hat die moralische Kraft, für Deutschland einen Frieden
                zu erwirken, der es vor dem Schlimmsten bewahrt. Die jetzige Umwälzung war notwendig, um im letzten
                Augenblick durch die Selbstregierung des Volkes die Entwicklung der Zustände ohne allzu schwere
                Erschütterung zu ermöglichen, bevor die feindliche Heere die Grenzen überfluten oder nach dem
                Waffenstillstand die demobilisierten deutschen Truppen das Chaos herbeiführen.</p>
            <p>
                Der Arbeiter-, Soldaten- und Bauernrat wird strengste Ordnung sichern. Ausschreitungen werden
                rücksichtslos unterdrückt. Die Sicherheit der Person und des Eigentums wird verbürgt.</p>
            <p>
                Die Soldaten in den Kasernen werden durch Soldatenräte sich selbst regieren und Disziplin
                aufrechterhalten. Offiziere, die sich den Forderungen der veränderten Zeit nicht widersetzen,
                sollen unangetastet ihren Dienst versehen.</p>
            <p>
                Wir rechnen auf die schaffende Mithilfe der gesamten Bevölkerung. Jeder Arbeiter an der neuen Freiheit
                ist willkommen! Alle Beamte bleiben in ihren Stellungen. Grundlegende soziale und politische
                Reformen werden unverzüglich ins Werk gesetzt.</p>
            <p>
                Die Bauern verbürgen sich für die Versorgung der Städte mit Lebensmitteln. Der alte Gegensatz zwischen
                Land und Stadt wird verschwinden. Der Austausch der Lebensmittel wird rationell organisiert werden.</p>
            <p>
                Arbeiter, Bürger Münchens! Vertraut dem Großen und Gewaltigen, das in diesen schicksalsschweren
                Tagen sich vorbereitet!</p>
            <p>
                Helft alle mit, daß sich die unvermeidliche Umwandlung rasch, leicht und friedlich vollzieht.
                In dieser Zeit des sinnlos wilden Mordens verabscheuen wir alles Blutvergießen. Jedes Menschenleben
                soll heilig sein!</p>
            <p>
                Bewahrt die Ruhe und wirkt mit am Aufbau der neuen Welt!</p>
            <p>
                Der Bruderkrieg der Sozialisten ist für Bayern beendet. Auf der revolutionären Grundlage, die jetzt
                gegeben ist, werden die Arbeitermassen zur Einheit zurückgeführt.</p>
            <p>
                Es lebe die bayerische Republik!<br/>
                Es lebe der Frieden!<br/>
                Es lebe die schaffende Arbeit aller Werktätigen!</p>
            <p> München, Landtag, in der Nacht zum 8. November 1918.</p>
                <h3>Der Rat der Arbeiter, Soldaten und Bauern:</h3>
            <p>
                Der erste Vorsitzende: Kurt Eisner<br/><br/>
                Bekanntmachung. Zur Aufrechterhaltung der Ordnung und Sicherheit arbeitet von heute an das gesamte
                Polizei- und Sicherheitspersonal im Auftrag und unter Kontrolle des Arbeiter- und Soldatenrates.<br/>
                Den Anordnungen dieser Organe ist unbedingt Folge zu leisten.</p>
            <h3>Erklärung des Münchner Polizeipräsidenten.</h3>
            <p>
                Ich verpflichte mich, bei der Ausübung des Sicherheitsdienstes den Anordnungen des Arbeiter- und
                Soldatenrates München Folge zu leisten. Sofern ich dieser Verpflichtung nicht nachkommen kann, muß ich
                mir das Recht des Rücktritts vorbehalten.</p>
            <p>
                München, 8. November 198, morgens 1 Uhr<br/>
                K. Polizepräsident: gez. v. Beckh
            </p>
        </>
    )
}

export default Page;
