import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <Container.Fullwidth key={key.toString() + 'slider'}>
            <Container.Novel id={key} sliderTitleId="nodelTitle">
                <div className="bg-grey">
                    <p>1919 Bamberg Spiegelsaal der Harmonie</p>
                    <h2>Im April 1919 wurden Regierung und Landtag nach Bamberg verlegt...</h2>
                    <StaticImage src="../../../../static/images/Bamberg/Novel/Szene_1_Bild_1.png" placeholder="none" alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Bamberg/Novel/Szene_1_Bild_2.png" placeholder="none"  alt=""/>
                <div className="bg-grey">
                    <h2>Eine bedeutende Landtagssitzung am <br/>12. August 1919</h2>
                    <StaticImage src="../../../../static/images/Bamberg/Novel/Szene_2_Bild_1.png" placeholder="none" alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Bamberg/Novel/Szene_2_Bild_2.png" placeholder="none" alt=""/>
                <StaticImage src="../../../../static/images/Bamberg/Novel/Szene_2_Bild_3.png" placeholder="none" alt=""/>
            </Container.Novel>
        </Container.Fullwidth>
    )
}

export default Novel

