import * as F05_02P1 from "./05_02/Page1";

import * as F05_03P1 from "./05_03/Page1";

import * as F05_04P1 from "./05_04/Page1";

import * as F05_06P1 from "./05_06/Page1";
import * as F05_06P2 from "./05_06/Page2";
import * as F05_06P3 from "./05_06/Page3";
import * as F05_06P4 from "./05_06/Page4";
import * as F05_06P5 from "./05_06/Page5";
import * as F05_06P6 from "./05_06/Page6";
import * as F05_06P7 from "./05_06/Page7";
import * as F05_06P8 from "./05_06/Page8";
import * as F05_06P9 from "./05_06/Page9";
import * as F05_06P10 from "./05_06/Page10";

const Pages = {
    '05_02': {
        1: ['/facsimile/bamberg/05_02_Aufruf/05_02_td47561_00001.jpg', F05_02P1],
    },
    '05_03': {
        1: ['/facsimile/bamberg/05_03_Frauenwahlrecht/05_03_04443.jpg', F05_03P1],
    },
    '05_04': {
        1: ['/facsimile/bamberg/05_04_Flugblatt/05_04_K.S. C b 610.jpg', F05_04P1],
    },
    '05_06': {
        1: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-08.jpg', F05_06P1],
        2: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-09.jpg', F05_06P2],
        3: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-10.jpg', F05_06P3],
        4: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-11.jpg', F05_06P4],
        5: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-12.jpg', F05_06P5],
        6: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-13.jpg', F05_06P6],
        7: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-14.jpg', F05_06P7],
        8: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-15.jpg', F05_06P8],
        9: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-16.jpg', F05_06P9],
        10: ['/facsimile/bamberg/05_06_Verfassung/Ma_102010-17.jpg', F05_06P10],
    },
};

export {Pages};
