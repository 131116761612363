import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Transkription zu:<br/>Flugblatt des Bamberger Tagblatts zur Verlegung des Regierungssitzes nach Bamberg,
                8. April 1919</h3>
            <p>
                Bamberger Tagblatt<br/>
                Bamberg, 8. April 1919
            </p>
            <p>
                Das Ministerium Hoffmann bleibt!<br/>
                Regierungssitz in Bamberg.<br/>
                Kundgebung der Regierung des Freistaates Bayern an die Bayerische Beamtenschaft.
            </p>
            <p>
                ** Die Nachricht, daß die sozialistische Regierung des Ministerpräsidenten Hoffmann zurückgetreten sei,
                ist unwahr. Der vom bayerischen Landtag einstimmig gewählte Ministerpräsident Hoffmann hat den Sitz der
                Regierung heute nach Bamberg verlegt. Diese Regierung ist die einzige Inhaberin der höchsten Gewalt
                Bayerns. Nur ihre Anordnungen und Befehle sind zu vollziehen. Alle von anderer Seite ergehenden
                Anweisungen sind ungültig.
            </p>
            <p>
                Bamberg, 7. April 1919<br/>
                Die Regierung des Freistaates Bayern:<br/>
                Gez. Hoffman, Ministerpräsident.

            </p>
        </>
    )
}

export default Page;
