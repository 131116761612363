import * as React from 'react'

const Page = () => {
    return (
        <>
            <p><strong>§ 81. </strong>Beschlüsse des Landtages, die eine Erhöhung der Ausgaben des Haushaltsplanes oder
                die Einstellung neuer Ausgaben bewirken, sind auf Verlangen des Gesamtministeriums erneut zu beraten.
                Diese Beratung bedarf ohne Zustimmung des Gesamtministeriums nicht vor Ablauf von vierzehn Tagen
                stattfinden. Der erste Beschluß bleibt wirksam, wenn er bei der erneuten Beratung von mindestens
                Zweidrittel der anwesenden Mitglieder bestätigt wird.
            </p>
            <p>
                <strong>§ 82. </strong>Die Höhe des Steuerbetrages ergibt sich für jeden Steuerpflichtigen aus den
                Anordnungen der einzelnen Steuergesetze, des Finanzgesetzes und im Falle der Haushaltsführung auf
                Grund eines vorläufigen Haushaltsplanes aus den vorläufigen Steueranordnungen des Gesamtministeriums.
            </p>
            <p>
                <strong>§ 83. </strong>Erübrigungen, die sich nach der Rechnung für ein Haushaltsjahr ergeben, sind als
                Einnahme in den Haushaltsplans des übernächsten Haushaltsjahres einzustellen. Fehlbeträge, mit denen die
                Rechnung für ein Haushaltsjahr abschließt, sind als Ausgaben im Haushaltsplane des übernächsten
                Haushaltsjahres vorzutragen.
            </p>
            <p>
                <strong>§ 84. </strong>(1) Dem Landtag ist alljährlich nah Ablauf des Haushaltsjahres über alle Einnahmen
                und Ausgaben des Staates Rechnung zu erstatten. Die Rechnungen haben die Ausführungen des Haushaltsplanes
                übersichtlich darzulegen und müssen mit seiner Gliederung genau übereinstimmen.
            </p>
            <p>
                (2) Der Rechnungshof hat diese Rechnungen zu prüfen.
            </p>
            <p>
                (3) Der Rechnungshof stellt alljährlich nach Beendigung dieser Prüfung in einem Bericht an das
                Gesamtministerium fest, inwieweit bei dem Vollzuge des Haushaltsplanes von den Landtagsbeschlüssen
                abgewichen worden ist. Das Gesamtministerium legt den Bericht alljährlich gleichzeitig mit den
                Rechnungsnachweisungen dem Landtage vor.
            </p>
            <p>
                <strong>§ 85. </strong>Auf Grund der Rechnungsnachweisungen beschließt der Landtag über die Entlastung
                der Ministerien.
            </p>
            <h4>9. Abschnitt:<br/>Heerwesen</h4>
            <p>
                <strong>§ 86. </strong>Jeder Bayer hat zur Verteidigung seines Vaterlandes nach den bestehenden Gesetzen
                mitzuwirken.
            </p>
            <p>
                <strong>§ 87. </strong>Die bayerischen Truppen bilden einen Teil der Wehrmacht des Deutschen Reiches.
                Sie sind ein geschlossener Truppenverband.
            </p>
            <p>
                <strong>§ 88. </strong>Die bewaffnete Macht darf zur Erhaltung der inneren Sicherheit und gesetzlichen
                Ordnung nur einschreiten, wenn die polizeilichen Zwangsmittel hierfür unzureichend sind und die
                zuständige bürgerliche Behörde in gesetzmäßiger Form das Aufgebot an die militärische Behörde erläßt.
            </p>
            <h4>10. Abschnitt:<br/>Verkehrswesen</h4>
            <p>
                <strong>§ 89. </strong>Die Staatseisenbahnen werden auf der Grundlage der gesetzlichen Bestimmungen nach
                Grundsätzen des allgemeinen Staatsinteresses verwaltet. Für die Genehmigung und Beaufsichtigung privater
                Eisenbahnunternehmungen sind besondere gesetzliche oder verordnungsmäßige Vorschriften zu erlassen.
            </p>
            <p>
                <strong>§ 90. </strong>Das Recht, Post-, Telegraphen- und Fernsprechanstalten zu errichten und zu
                betreiben, steht ausschließlich dem Staate zu.
            </p>
            <p>
                <strong>§ 91. </strong>Durch Gesetz kann der staatlichen Verkehrsverwaltung das Recht zum
                ausschließlichen Betrieb anderer öffentlicher Verkehrsmittel gegeben oder die Genehmigung privaten
                Betriebes gestattet werden.
            </p>
            <h4>11. Abschnitt:<br/>Schluß- und Übergangsbestimmungen</h4>
            <p>
                <strong>§ 92. </strong>Änderungen an dieser Verfassung können nur durch Zweidrittelmehrheit der
                gesetzlichen Mitgliederzahl des Landtages beschlossen werden. Dasselbe gilt von Änderungen am
                Staatsgebiete, soferne sie nicht bloße Grenzregelungen sind.
            </p>
            <p>
                <strong>§ 93. </strong>(1) Jeder Staatsangehörige und jede juristische Person, die in Bayern ihren Sitz
                hat, haben das Recht der Beschwerden an den Staatsgerichtshof, wenn sie glauben, durch die Tätigkeit
                einer Behörde in ihrem Recht unter Verletzung dieser Verfassung geschädigt zu sein. Die Beschwerde ist
                nur zulässig, wenn vorher ohne Erfolg beim Ministerium Abhilfe nachgesucht worden oder der Rechtsweg
                erschöpft ist.
            </p>
            <p>
                (2) Die Entscheidungen des Staatsgerichtshofes sind dem Beschwerdeführer, dem Landtage und dem
                Ministerium mitzuteilen.
            </p>
            <p>
                <strong>§ 94. </strong>Alle Gesetze, welche vor dem 7. November 1918 in Bayern in Geltung standen,
                behalten ihre Gültigkeit, sofern sie nicht mit Bestimmungen dieser Verfassung oder mit den durch das
                Übergangsgesetz vom 23. März 1919 (GVBl. S. 119) bestätigten Anordnungen der provisorischen Regierung
                in Widerspruch stehen oder in dem durch diese Verfassung geregelten Wege der Gesetzgebung aufgehoben
                oder abgeändert werden. Die hiernach fortgeltenden Gesetze werden, auch wenn sie bisher Verfassungsgesetze
                waren, als einfache Gesetze behandelt.
            </p>
            <p>
                <strong>§ 95. </strong>Diese Verfassungsurkunde tritt am Tage ihrer Verkündung im Gesetz- und
                Verordnungsblatt in Kraft.
            </p>
            <p>
                Vorstehende Verfassung ist am 12. August 1919 durch den Landtag beschlossen worden.
            </p>
            <p>
                Bamberg, den 14. August 1919
            </p>
        </>
    )
}

export default Page;
