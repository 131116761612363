import Headerimage from "./Headerimage";
import Novel from "./Novel";
import TextBlock1Blue from "./TextBlock1Blue";
import TextBlock2White from "./TextBlock2White";
import TextBlock3Beige from "./TextBlock3Beige";
import TextBlock4White from "./TextBlock4White";
import TextBlock5More from "./TextBlock5More";
import Video from "./Video";

const Parts = [
    Headerimage,
    TextBlock1Blue,
    Novel,
    TextBlock2White,
    TextBlock3Beige,
    TextBlock4White,
    TextBlock5More,
    Video,
];

export {Parts};
