import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                <strong>§ 62. </strong>(1) Die Landesgesetze sind vom Landtagspräsidenten und Gesamtministerium, die
                Staatsverträge vom Landtagspräsidenten und Ministerpräsidenten auszufertigen und zu verkünden.
            </p>
            <p>
                (2) Der Ministerpräsident hat den Vorsitz und Stichentscheid im Gesamtministerium und überwacht den
                Vollzug der Beschlüsse.
            </p>
            <p>
                (3) Der Ministerpräsident hat das Recht, in allen Staatsangelegenheiten sich durch Beamte aller
                Ministerien und durch sonstige ihm hierzu berufene Personen beraten zu lassen.
            </p>
            <p>
                <strong>§ 63. </strong>In allen Angelegenheiten, über die das Gesamtministerium zu beschließen hat,
                entscheidet Stimmenmehrheit der anwesenden Minister.
            </p>
            <p>
                <strong>§ 64. </strong>(1) Das Gesamtministerium wacht über die Sicherheit des Staates. Es hat bei
                drohender Gefahr die Maßnahmen zu ergreifen, welche die Ruhe und Ordnung im Innern sichern oder gegenüber
                der Gefahr eines Angriffes von außen unmittelbar erforderlich sind. Zu diesem Zwecke kann es
                vorübergehend die verfassungsmäßigen Grundrechte ganz oder teilweise außer Kraft setzen.
            </p>
            <p>
                (2) Um einer augenblicklichen Gefahr zu begegnen, kann das Gesamtministerium über die bewaffnete Macht
                verfügen und die erforderlichen Anordnung treffen.
            </p>
            <p>
                <strong>§ 65. </strong>(1) Die Minister haben das Recht, jederzeit im Landtage zu erscheinen und die
                Vorlagen ihres Geschäftskreises persönlich zu vertreten oder durch Bevollmächtigte vertreten zu lassen.
            </p>
            <p>
                (2) Durch Beschluß des Gesamtministeriums kann jeder Minister auch zur Vertretung von Vorlagen eines
                anderen als seines eigenen Geschäftskreises ermächtigt werden.
            </p>
            <p>
                (3) Die Minister haben das Recht, im Landtage jederzeit das Wort zu ihren Vorlagen oder zu den Vorlagen,
                mit deren Vertretung sie beauftragt sind, zu verlangen. Auch können sie verlangen, daß ein Gegenstand
                zunächst in einen Ausschuß des Landtages zur Beratung verwiesen werden. Zu jedem Gegenstande der
                Verhandlung müssen sie auch Begehren gehört werden. Die gleichen Rechte stehen auch den Bevollmächtigten
                der Minister zu.
            </p>
            <p>
                (4) Jeder Minister ist verpflichtet, dem Landtag auf Verlangen über die Verwaltung seines Geschäftskreises
                Auskunft zu geben.
            </p>
            <p>
                (5) Im Falle der Auflösung des Landtages oder des Ablaufes der Landtagsdauer ordnet das Gesamtministerium
                oder der von ihm damit betraute Minister die Neuwahlen an.
            </p>
            <p>
                <strong>§ 66. </strong>(1) Sind alle Minister zurückgetreten, so haben sie bis zur Bildung eines neuen
                Ministeriums die Geschäfte weiterzuführen.
            </p>
            <p>
                (2) Mit der Vertretung der einzelnen Minister im Fall ihrer Verhinderung und mit der Leitung eines
                Ministeriums im Falle seiner Erledigung können nur Staatssekretäre und Staatsräte betraut werden.
            </p>
            <p>b) Behörden, Staatsdienst</p>
            <p>
                <strong>§ 67. </strong>(1) Den Beamten wird die Freiheit der religiösen und politischen Gesinnung und des
                Zusammenschlusses gewährleistet.
            </p>
            <p>
                (2) Dienstrecht, Diensteinkommen und Versorgung werden gesetzlich geregelt. Die wohlerworbenen Rechte der
                Beamten sind unverletzlich. Für die vermögensrechtlichen Ansprüche der Beamten steht der Rechtsweg offen.
            </p>
            <p>
                (3) Unwiderruflich angestellte Staatsbeamte können wegen schuldhaften Verhaltens nur unter den
                gesetzlichen Voraussetzungen und Formen vorläufig ihres Amtes enthoben, einstweilen oder endgültig in den
                Ruhestand versetzt oder entlassen werden.
            </p>
            <p>
                <strong>§ 68. </strong>(1) Zu einem öffentlichen Amte kann nur berufen werden, wer seit mindestens fünf
                Jahren die deutsche Reichsangehörigkeit besitzt. Ausnahmen sind nur auf Grund eines Beschlusses des
                Gesamtministeriums zulässig.
            </p>
            <p>
                (2) Die Staatsbeamten werden ernannt. Die Ernennung geschieht, soweit nicht das Gesetz anderes bestimmt,
                auf Lebenszeit.
            </p>
            <p>
                <strong>§ 69. </strong>(1) Richter können nur aus gesetzlichen Gründen und im gesetzlich geregelten
                Verfahren gegen ihren Willen versetzt, ihres Amtes enthoben, in den Ruhestand versetzt oder entlassen
                werden. Die Mitglieder des Rechnungshofes sind den Richtern gleichgestellt.
            </p>
            <p>
                (2) Rechtspflege und Verwaltung sind getrennt. Den Gerichten können Geschäfte der Justizverwaltung und
                der freiwilligen Gerichtsbarkeit übertragen werden.
            </p>
            <p>
                (3) Die Entscheidung von Streitigkeiten über die Zuständigkeit zwischen den Gerichten und
                Verwaltungsbehörden erfolgt in gesetzlich geregeltem Verfahren durch besonders hierfür gebildete Gerichte.
            </p>
            <p>
                (4) Alle Streitigkeiten über Rechte und Ansprüche des bürgerlichen Rechtes und alle Strafsachen sind den
                bürgerlichen Gerichten zur Entscheidung zuzuweisen, soweit nicht durch diese Verfassung die Verfassung
                des Deutschen Reiches oder ein besonderes Gesetz etwas anderes angeordnet ist.
            </p>
            <p>
                (5) Der Staat nimmt in Sachen des Bürgerlichen Rechtes vor den ordentlichen Gerichten Recht.
            </p>
            <p>
                (6) Anhängige Streitsachen und strafrechtliche Untersuchungen können weder durch den Landtag noch durch
                die Ministerien oder sonstige Verwaltungsbehörden gehemmt, von den Gerichten erkannte Strafen können nur
                nach den Vorschriften dieser Verfassung oder der Verfassung des Deutschen Reiches gemildert oder
                erlassen werden.
            </p>
            <p>
                <strong>§ 70. </strong>(1) Über Ministeranklagen, Verfassungsbeschwerden und Verfassungsstreitigkeiten,
                für welche der Rechtsweg nicht geöffnet ist, entscheidet er Staatsgerichtshof.
            </p>
            <p>
                (2) Er besteht aus dem Präsidenten des Obersten Landesgerichtes als Vorsitzendem, aus acht Richtern,
                von denen drei dem Verwaltungsgerichtshof angehören müssen, und zehn Mitgliedern, die vom Landtage für
                seine Dauer mit Zweidrittelmehrheit der anwesenden Mitglieder zu wählen sind.
            </p>
            <p>
                (3) Die Richter des Verwaltungsgerichtshofes werden vom Präsidenten des Gerichtes, die übrigen Richter
                vom Präsidenten des Obersten Landesgerichts auf die Dauer ihres Hauptamtes ernannt.
            </p>
            <p>
                (4) Das Verfahren des Staatsgerichtshofes wird durch Gesetz geregelt.
            </p>
            <p>
                <strong>§ 71. </strong>(1) Für bestrittene Rechtsansprüche und Verbindlichkeiten auf dem Gebiete des
                öffentlichen Rechts steht nach Maßgabe der Gesetze das Verwaltungsrechtsverfahren offen.
            </p>
        </>
    )
}

export default Page;
