import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "../bamberg/facsimile";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Die Staatsgewalt<br/>
                        <span>&nbsp;</span>geht von der Gesamtheit<br/>
                        des Volkes aus.“</h2>
                    <div className="inner-container">
                        <p>Im Spiegelsaal der Bamberger Harmonie fanden 1919 die Beratungen des Landtags und die
                            Abstimmung über die erste demokratische Verfassung Bayerns statt.
                        </p>
                        <p>
                            In München hatte sich nach der Revolution die Lage zunächst stabilisiert. Aus ersten
                            allgemeinen Wahlen im Januar 1919 ging ein neuer bayerischer Landtag mit klaren Mehrheiten
                            für die Parteien der Mitte hervor: Bayerische Volkspartei, Mehrheitssozialdemokraten,
                            Deutsche Demokratische Partei. Sie strebten eine parlamentarische Demokratie an. Als der
                            Landtag am 21. Februar zusammentreten sollte, ermordete der junge Offizier Anton Graf von
                            Arco auf Valley den Vorsitzenden der Revolutionsregierung, den Unabhängigen Sozialdemokraten
                            Kurt Eisner. Im Anschluss kam es zu Schießereien im Landtag mit Toten und dem schwer
                            verletzten Vorsitzenden der Mehrheitssozialdemokraten Erhard Auer, der sich schon 1917 mit
                            einem umfassenden Antrag im Landtag für die Demokratisierung eingesetzt hatte. Erst im März
                            1919 konnte der Landtag wieder zusammentreten und den Sozialdemokraten Johannes Hoffmann zum
                            Vorsitzenden des Ministerrats wählen. Die folgende Ausrufung von revolutionären
                            Räterepubliken führte zur Destabilisierung der Verhältnisse in München. Regierung und
                            Landtag wichen nach Bamberg aus.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Die bereits von der Revolutionsregierung Eisner eingeleitete Arbeit an einer neuen
                                Verfassung wurde in Bamberg wieder aufgegriffen und in einem Übereinkommen der Parteien
                                entscheidend vorangebracht. In Bamberg verabschiedete das Plenum des Landtags im August
                                mit großer Mehrheit die erste demokratische Verfassung Bayerns. Sie trat am 15.
                                September 1919 in Kraft.
                            </p>
                            <p>
                                Die „Bamberger Verfassung“ setzte Prinzipien einer parlamentarisch-repräsentativen
                                Demokratie um. Erstmals waren in der bayerischen Verfassung die Volkssouveränität, das
                                allgemeine Wahlrecht und aufseiten der Legislative neben dem Landtag auch plebiszitäre
                                Instrumente wie Volksbegehren und Volksentscheid sowie aufseiten der Exekutive das Amt
                                des bayerischen Ministerpräsidenten verankert.
                            </p>
                        </Container.More>
                        <br/>
                        {/*Facsimile*/}
                        <br/>
                        <div className="center">
                            <figure className="lightbox">
                                <Container.Facsimile
                                    pages={Pages['05_06']}
                                    img="/images/Bamberg/bamberg_bild_0015.jpg"
                                    alttext="Verfassung des Freistaats Bayern von 1919">
                                    <figcaption>
                                        Verfassung des Freistaats Bayern von 1919
                                        <span className="description">
                                        In diesem Exemplar der Verfassungsurkunde wurden handschriftlich von
                                    Josef von Graßmann kleine Änderungen aus der Landtagssitzung vom 12. August
                                    hinzugefügt. Die aus der endgültigen Textredaktion hervorgegangene Fassung
                                    wurde am 15. September 1919 im Gesetz- und Verordnungsblatt veröffentlicht.
                                    </span>
                                        <span className="copy">Abb. Bayerisches Hauptstaatsarchiv, MA 102010</span>
                                    </figcaption>
                                </Container.Facsimile>
                            </figure>
                        </div>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
