import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock5More = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="beige" key={key}>
                <Container.Row>
                    <div className="col-12">
                        <h2>Prof. Dr. Robert Piloty (Deutsche Demokratische Partei)</h2>
                    </div>
                </Container.Row>
                <Container.Row>
                    <div className="col-6">
                        <blockquote>
                            „Unter uns gesagt freilich hege ich bei alledem große Sorge: Was wird unser armes
                            seit 1000 Jahren an Vormundschaft gewöhntes Volk mit all diesen schweren Rechten
                            anfangen?“
                        </blockquote>
                        <p>
                            Robert Piloty war 1919 und 1920 Abgeordneter im Landtag und als Jurist maßgeblich an der
                            Ausarbeitung der Verfassung von 1919 beteiligt. Er war Schüler des berühmten
                            Staatsrechtslehrers Max von Seydel und wurde 1895 an der Universität Würzburg Professor für
                            Staats- und Verwaltungsrecht. Als Förderer des Gedankens der Volksbildung initiierte er 1919
                            die Gründung der Volkshochschule in Würzburg.
                        </p>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Bamberg/bamberg_bild_0009.jpg"}
                               data-glightbox="description: .custom-desc1_10; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Robert Piloty (1863–1926), o. D."/>
                            </a>
                            <div className="glightbox-desc custom-desc1_10">
                                <h2>Robert Piloty (1863–1926), o. D.</h2>
                                <p>
                                    „Unter uns gesagt freilich hege ich bei alledem große Sorge: Was wird unser armes
                                    seit 1000 Jahren an Vormundschaft gewöhntes Volk mit all diesen schweren Rechten
                                    anfangen?“
                                </p>
                                <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                            </div>
                            <figcaption>
                                Robert Piloty (1863–1926), o. D.
                                </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-12">
                        <h2>Dr. Rosa Kempf (Deutsche Demokratische Partei)</h2>
                    </div>
                </Container.Row>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Bamberg/bamberg_bild_0011.jpg"}
                               data-glightbox="description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0011.jpg"  width={335} layout="fixed" placeholder="none" alt="Rosa Kempf (1874–1948), o. D."/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <h2>Rosa Kempf (1874–1948), o. D.</h2>
                                <p>
                                    „[…] wir werden noch lange zu kämpfen haben, bis im ‚Leben‘ die Gleichberechtigung
                                    der Frauen durchgedrungen ist.“
                                </p>
                                <p className="copy">Abb. Bildarchiv Bayerischer Landtag, Foto: Adalbert Werner</p>
                            </div>
                            <figcaption>
                                Rosa Kempf (1874–1948), o. D.
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <blockquote>
                            „[…] wir werden noch lange zu kämpfen haben, bis im ‚Leben‘ die Gleichberechtigung
                            der Frauen durchgedrungen ist.“
                        </blockquote>
                        <p>
                            Rosa Kempf zählte 1919 zu den ersten acht Frauen unter den 180 Landtagsabgeordneten. Nach
                            ihrer Ausbildung zur Lehrerin studierte sie in München Philosophie und Staatswissenschaften
                            und schloss mit einer Dissertation über die Lebensbedingungen junger Fabrikarbeiterinnen ab.
                            Im Dezember 1918 hielt sie als erste Frau eine Rede vor dem Provisorischen Nationalrat. Im
                            Landtag trat sie ab 1919 selbstbewusst für Frauenrechte ein. Bei den Neuwahlen im Juni 1920
                            wurde sie nicht wiedergewählt und ging zurück nach Frankfurt als Dozentin an ihre Soziale
                            Frauenschule.
                        </p>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="beige" key={key}>
                <Container.Row>
                    <div className="col-12">
                        <h2>Ellen Ammann (Bayerische Volkspartei)</h2>
                    </div>
                </Container.Row>
                <Container.Row>
                    <div className="col-6">
                          <blockquote>
                              „Nur wer [...] die Zusammenhänge der wirtschaftlichen und sozialen Bewegung unserer
                              Zeit nicht kennt, kann die Notwendigkeit einer katholischen Frauenorganisation
                              leugnen.“
                          </blockquote>
                        <p>
                            Als Abgeordnete der ersten Stunde gehörte sie von 1919 bis zu ihrem Tod im Jahr 1932 dem
                            Landtag an. Die überzeugte Katholikin engagierte sich sab 1890 in der bürgerlichen
                            Frauenbewegung. Unermüdlich setzte sie sich nicht nur für die Emanzipation der Frauen ein,
                            sondern besonders für die Belange mitteloser und schwer arbeitender Frauen. Sie war
                            Gründerin des Katholischen Frauenbunds in München, der Bahnhofsmission und der heutigen
                            Katholischen Stiftungsfachhochschule. Mutig trat sie schon früh gegen den
                            Nationalsozialismus ein.
                        </p>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Bamberg/bamberg_bild_0007.jpg"}
                               data-glightbox="description: .custom-desc1_12; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Ellen Ammann (1870–1932), 1917"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_12">
                                <h2>Ellen Ammann (1870–1932), 1917</h2>
                                <p>
                                    „Nur wer [...] die Zusammenhänge der wirtschaftlichen und sozialen Bewegung unserer
                                    Zeit nicht kennt, kann die Notwendigkeit einer katholischen Frauenorganisation
                                    leugnen.“
                                </p>
                                <p className="copy">Abb. Archiv des Erzbistums München und Freising</p>
                            </div>
                            <figcaption>
                                Ellen Ammann (1870–1932), 1917
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-12">
                        <h2>Heinrich Held (Bayerische Volkspartei)</h2>
                    </div>
                </Container.Row>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Bamberg/bamberg_bild_0001.jpg"}
                               data-glightbox="description: .custom-desc1_11; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Bamberg/bamberg_bild_0001.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Heinrich Held (1868–1938), o. D."/>
                            </a>
                            <div className="glightbox-desc custom-desc1_11">
                                <h2>Heinrich Held (1868–1938), o. D.</h2>
                                <p>
                                    „Der Landtag steht ja im Brenn- und Mittelpunkte der neuen Verfassung. […] Er allein
                                    ist der Gesetzgeber der Zukunft.“
                                </p>
                                <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                            </div>
                            <figcaption>
                                Heinrich Held (1868–1938), o. D.
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                           <blockquote>
                               „Der Landtag steht ja im Brenn- und Mittelpunkte der neuen Verfassung. […] Er allein
                               ist der Gesetzgeber der Zukunft.“
                           </blockquote>
                        <p>
                            Der aus dem hessischen Erbach stammende Heinrich Held gehörte ab 1907 dem bayerischen
                            Landtag an. Als Fraktionsvorsitzender der Bayerischen Volkspartei, die von 1919 bis 1933 die
                            stärkste Gruppe im Landtag stellte, nahm er wesentlichen Einfluss auf die Ausarbeitung der
                            Bamberger Verfassung. Nach der krisenhaften Zuspitzung der politischen Lage infolge des
                            Hitlerputschs wählte der bayerische Landtag 1924 Held zum Ministerpräsidenten. Ihm gelang
                            eine Stabilisierung der Verhältnisse. 1933 wurde er von den Nationalsozialisten aus dem Amt
                            gedrängt.
                        </p>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock5More
