import * as React from 'react'

const Page = () => {
    return (
        <>
            <h4>7. Abschnitt<br/><br/>Staatsverwaltung</h4>
            <p>
                a) Ministerium
            </p>
            <p>
                <strong>§ 57. </strong>(1) Dem Staatsministerium obliegt die Leitung der gesamten Staatsverwaltung, der
                Vollzug aller Gesetze, Reichsverordnungen und Beschlüsse des Landtages sowie die Vertretung Bayerns
                gegenüber dem Reiche, den einzelnen Staaten des Reiches und den auswärtigen Mächten im Rahmen der
                Verfassung des Deutschen Reiches.
            </p>
            <p>
                (2) Alle Staatsbehörden sind ihm untergeordnet. Die Unabhängigkeit der Rechtsprechung und der Tätigkeit
                des Rechnungshofes wird hierdurch nicht berührt.
            </p>
            <p>
                (3) Das Ministerium handhabt auch in den gesetzlichen Schranken die Oberaufsicht über die Selbstverwaltung
                der Gemeinden und Gemeindeverbände.
            </p>
            <p>
                <strong>§ 58. </strong>(1) Das Gesamtministerium wird durch den Landtag bestellt. Der Landtag wählt den
                Ministerpräsidenten. Zur Gültigkeit der Wahl ist die Zustimmung der Mehrheit der gesetzlichen
                Mitgliederzahl erforderlich. Der Ministerpräsident unterbreitet dem Landtag eine Vorschlagsliste für die
                übrigen Ministerien. Er besetzt diese im Einverständnisse mit dem Landtage. Bei Erledigung einzelner
                Ministerien wird in gleicher Weise verfahren.
            </p>
            <p>
                (2) Den Ministern können Staatssekretäre als ständige politische Stellvertreter beigegeben werden. Sie
                werden auf Vorschlag des Ministers im Einverständnisse mit dem Landtage durch das Gesamtministerium
                ernannt und entlassen.
            </p>
            <p>
                (3) Jeder Minister hat einen bestimmten Geschäftskreis zu verwalten. Alle Geschäfte der Staatsverwaltung
                sind nach Maßgabe der Gesetze vom Staatsministerium unter die Ministerien zu verteilen. Die Verteilung
                ist im Gesetz- und Verordnungsblatte bekannt zu machen.
            </p>
            <p>
                (4) Als Vertreter des Ministerpräsidenten für die ihm vorbehaltenen Geschäfte ist durch das
                Gesamtministerium ein Minister zu bestellen.
            </p>
            <p>
                <strong>§ 59. </strong>(1) Die Minister bedürfen zu ihrer Amtsführung des Vertrauens des Landtages. Das
                Gleiche gilt für die Staatssekretäre, soweit sie in Vertretung der Minister selbständig handeln.
            </p>
            <p>
                (2) Das Gesamtministerium, die Minister und Staatssekretäre sind für ihre Amtszeit dem Landtage
                verantwortlich. Sie können jederzeit von ihrem Amte zurücktreten; sie müssen zurücktreten, wenn ihnen das
                Mißtrauen des Landtages ausgesprochen wird.
            </p>
            <p>
                (3) Die Minister und Staatssekretäre dürfen ein anderes besoldetes Amt, einen Beruf oder ein Gewerbe
                nicht ausüben.
            </p>
            <p>
                <strong>§ 60. </strong>(1) Die Minister haben Anspruch auf Besoldung, die durch Gesetz zu regeln ist.
                Ruhegehalt und Hinterbliebenenversorgung stehen ihnen nicht zu.
            </p>
            <p>
                (2) Ein Minister, der zur Zeit seiner Bestellung im bayerischen Staatsdienste stand, erhält bei seinem
                Rücktritt einen Ruhegehalt, der dem Wartegeld aus dem Gehalte seiner früheren Stelle entspricht. War er
                zur Zeit seiner Bestellung im Ruhestande, so lebt sein Anspruch aus den früher bezogenen Ruhegehalt
                wieder auf.
            </p>
            <p>
                (3) Der Minister hat bei seinem Rücktritt Anspruch auf Übertragung einer etatmäßigen Beamtenstelle,
                die seinem früheren Amte gleichwertig und gleichartig ist.
            </p>
            <p>
                (4) In den Fällen der Absätze 2 und 3 sind Gehaltsvorrückungen und Beförderungsmöglichkeiten bis zum
                Abgang als Minister zu berücksichtigen.
            </p>
            <p>
                (5) Nach dem Ruhegehalte wird die Hinterbliebenenversorgung gemäß dem Staatsbeamtengesetze geregelt.
            </p>
            <p>
                (6) Vorstehende Bestimmungen finden auf Staatssekretäre entsprechende Anwendung.
            </p>
            <p>
                <strong>§ 61. </strong>Für die Aufgaben und die Geschäftsführung des Gesamtministeriums und der Ministerien bestehen folgende Grundsätze:
            </p>
            <p>
                1. Die Staatsverwaltung wird nach der Verfassung, den Gesetzen und dem Haushaltsplane geführt.
            </p>
            <p>
                2. Das Gesamtministerium erledigt die ihm durch diese Verfassung, die Gesetze und allgemeinen Verordnungen
                zugewiesenen Aufgaben. Alle übrigen Aufgaben sind nach den oben aufgeführten Grundsätzen von den einzelnen
                Ministerien zu erfüllen.<br/>Das Gesamtministerium ist befugt, die Entscheidung über einzelne dieser
                Angelegenheiten an sich zu ziehen, soferne sie von allgemeiner politischer Bedeutung sind.
            </p>
            <p>
                3. Das Gesamtministerium vertritt Bayern gegenüber dem Reich und anderen Staaten, soweit diese Geschäfte
                nicht einem besonderen Ministerium übertragen sind.
            </p>
            <p>
                4. Das Gesamtministerium ernennt die Vertreter der Minister und die Vorstände der den Ministerien
                unmittelbar untergeordneten Behörden. Die übrigen Beamten werden durch die zuständigen einzelnen Minister
                oder die von ihnen beauftragten Behörden ernannt.
            </p>
            <p>
                5. Jeder Minister übt die Dienstaufsicht über die Behörden und Beamten seines Dienstzweiges und entscheidet
                vorbehaltlich der Zuständigkeit der Gerichte und der Verwaltungsgerichte über die Beschwerden, welche sich
                aus seinem Geschäftsbereich ergeben.
            </p>
            <p>
                6. Das Gesamtministerium erläßt unter Beachtung des § 46 die allgemeinen Verwaltungsanordnungen.
            </p>
            <p>
                7. Rechtsverordnungen können durch das Gesamtministerium oder die einzelnen Minister nur auf Grund
                gesetzlicher Ermächtigung erlassen werden. Das Notverordnungsrecht steht dem Gesamtministerium nach
                näherer gesetzlicher Bestimmung zu.
            </p>
            <p>
                8. Das Gesamtministerium beschließt über alle Vorlagen, die dem Landtag im Namen der Regierung zu machen
                sind.
            </p>
            <p>
                9. Jedes Ministerium bearbeitet die Gesetzentwürfe, welche sich auf seinen Geschäftskreis erstrecken.
            </p>
            <p>
                10. Jedes Ministerium entwirft den Haushaltsplan seines Geschäftskreises und ist für dessen Vollzug
                verantwortlich.
            </p>
            <p>
                11. Das Ministerium der Finanzen stellt den Gesamthaushaltsplan auf und überwacht dessen Vollzug.
            </p>
        </>
    )
}

export default Page;
