import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>Im Namen des Landtages:<br/>
                Franz Schmitt, Präsident.
            </p>
            <p>
                Das Gesamtministerium:<br/>
                Hoffmann<br/>
                Dr. Ernst Müller<br/>
                Endres<br/>
                Speck<br/>
                Frauendorfer<br/>
                Segitz<br/>
                Freyberg<br/>
                Hamm<br/>
                Schneppenhorst<br/>
            </p>
        </>
    )
}

export default Page;
