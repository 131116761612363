import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                (2) Die mit der Verwaltungsrechtspflege betrauten Behörden erkennen mit richterlicher Unabhängigkeit.
                Ihre Erkenntnisse binden auch die Organe der öffentlichen Gewalt.
            </p>
            <p>
                (3) Zuständigkeit und Verfahren werden durch Gesetz geregelt.
            </p>
            <p>
                <strong>§ 72. </strong>Die mit der Rechtspflege betrauten Behörden prüfen bei ihren Entscheidungen,
                ob ein anzuwendendes Gesetz nicht mit einer Bestimmung der Verfassung des Deutschen Reiches, dieser
                Verfassung oder einem anderen Verfassungsgesetz in Widerspruch steht.
            </p>
            <p>
                <strong>§ 73. </strong>Die Geschäfts- und Verhandlungssprache im öffentlichen Dienst ist die deutsche
                Sprache.
            </p>
            <h4>8. Abschnitt:<br/>Gesetzgebung, Staatshaushalt</h4>
            <p>
                <strong>§ 74. </strong>(1) Alle für die Einwohner des Staates verbindlichen Rechtsvorschriften, welche
                die Freiheit der Person oder das Vermögen betreffen, können nur im Wege der Gesetzgebung erlassen,
                rechtsverbindlich erläutert, abgeändert oder aufgehoben werden.
            </p>
            <p>
                (2) Keine Abgabe oder Steuer darf ohne gesetzliche Anordnung erhoben werden.
            </p>
            <p>
                <strong>§ 75. </strong>Alle Gesetze, Rechtsverordnungen und Verwaltungsverordnungen im Sinne des § 46
                sind im Gesetz- und Verordnungsblatte bekannt zu machen. Die Gesetze treten, wenn sie nichts anderes
                bestimmen, mit dem vierzehnten Tage nach dem Ablaufe des Tages der Ausgabe des Blattes in Kraft.
            </p>
            <p>
                <strong>§ 76. </strong>(1) Der Landtag berät und beschließt über alle Anträge zur Gesetzgebung, die von
                seinen Mitgliedern, vom Gesamtministerium oder von der Staatsbürgerschaft an ihn gerichtet werden. Er
                entscheidet endgültig über die Zulässigkeit und Rechtsgültigkeit der Volksbegehren.
            </p>
            <p>
                (2) Volksbegehren im Sinne des § 10 Abs. 1 Ziffer 1 und 2 können nur als förmliche Gesetzentwürfe
                eingebracht werden. Jedes Begehren hat sich auf einen bestimmten Gegenstand zu beschränken und ist
                eingehend zu begründen.
            </p>
            <p>
                (3) Wird das Volksbegehren vom Landtag abgelehnt, oder mit Veränderungen angenommen, so ist er der
                Volksentscheidung zu unterbreien. Wenn der Gesetzentwurf des Volksbegehrens einen von ihm beschlossenen
                Entwurf gegenüberstellen. In diesem Falle sind beide Entwürfe der Volksentscheidung zu unterbreiten.
                Wenn der Gesetzentwurf des Volksbegehrens vom Landtag unverändert angenommen worden ist, so findet
                hierüber eine Volksentscheidung nur statt, wenn sie nach § 77 Abs. 2 durch Volksbegehren verlangt wird.
            </p>
            <p>
                <strong>§ 77. </strong>(1) Ausgenommen von der Volksentscheidung sind:<br/>
                1. Finanzgesetze und Gesetze über Steuern und Abgaben,<br/>
                2. Staatsverträge,<br/>
                3. Gesetze über Grenzregelungen,<br/>
                4. Gesetze, die sich auf die Einrichtung von Behörden und die Besoldung der Staatsbeamten beziehen,<br/>
                5. Ausführungsgesetze zu Reichsgesetzen, soferne diese bindend Anweisungen über die Erlassung oder Inhalt
                der Ausführungsgesetze geben,<br/>
                6. die vom Landtag als dringend bezeichneten Gesetze.
            </p>
            <p>
                (2) Über andere Gesetze, die der Landtag ohne Volksbegehren beschlossen hat, kann durch Volksbegehren
                oder Beschluß des Gesamtministeriums der Volksentscheidung binnen zwei Monaten angerufen werden. Vor
                Ablauf dieser Frist darf das Gesetz nicht ausgefertigt und verkündigt werden.
            </p>
            <p>
                (3) Kommt eine rechtsgültige Volksentscheidung über einen Landtagsbeschluß zur Gesetzgebung nicht
                zustande, so gilt er als bestätigt.
            </p>
            <p>
                (4) Ein durch Volksentscheid endgültig abgelehntes Volksbegehren kann vor Ablauf eines Jahres, bei
                Verfassungsänderungen vor Ablauf von drei Jahren nicht wiederholt werden.
            </p>
            <p>
                <strong>§ 78. </strong>Haushaltsjahr und Rechnungsjahr beginnen am 1. April und endigen mit dem 31. März
                des nächsten Jahres.
            </p>
            <p>
                <strong>§ 79. </strong>(1) Alle Einnahmen und Ausgaben des Staates sind alljährlich auf den Haushaltsplan
                zu bringen, soweit nicht auf Grund gesetzlicher Bestimmungen oder mit Zustimmung des Landtages besondere
                Vermögensbestände (Fonds) gebildet und mit besonderer Verwaltung und Verrechnung ausgeschieden sind.
                Diese Bestände sind nach Kapital, Einnahmen und Ausgaben mit dem Haushaltsplane zusammen besonders
                auszuweisen.
            </p>
            <p>
                (2) Der Haushaltsplan wird dem Landtage spätestens am 1. Oktober zur Beschlußfassung vorgelegt.
            </p>
            <p>
                (3) Ausgaben, die zur Erfüllung gesetzlicher Verpflichtungen notwendig sind oder auf gerichtlich
                klagbaren Verpflichtungen des Staates beruhen, und die ihrem Zwecke nach dauernden und als solche vom
                Landtag anerkannten, notwendigen Ausgaben müssen in den Haushaltsplan eingestellt und der
                Steuerbewilligung zugrunde gelegt werden.
            </p>
            <p>
                (2) Die Staatsbeamten werden ernannt. Die Ernennung geschieht, soweit nicht das Gesetz anderes bestimmt,
                auf Lebenszeit.
            </p>
            <p>
                <strong>§ 80. </strong>(1) Auf Grund des Haushaltsplanes beschließt der Landtag durch Gesetz über die
                nach den bestehenden Steuergesetzen zu erhebenden Steuern (Finanzgesetz).
            </p>
            <p>
                (2) Kommt der Haushaltsplan nicht rechtzeitig mit seinem ganzen Umfange zustande, so ist alsbald durch
                das Gesamtministerium einvorläufiger Haushaltsplan zu beschließen, und der Haushaltsführung bis zum
                Zustandekommen des endgültigen Haushaltsplanes zugrunde zu legen. In den vorläufigen Haushaltsplan sind
                die Ausgaben, über die in der Tagung bereits endgültig beschlossen worden ist, ferner aus den noch nicht
                abgeschlossenen Teilen des Gesamthaushaltsplanes alle gesetzlich notwendigen und alle als dauernd
                anerkannten notwendigen Ausgaben aufzunehmen.
            </p>
            <p>
                (3) In diesem Falle können durch vorläufige Steueranordnung des Gesamtministeriums die bisher erhobenen
                Steuern im bisherigen Umfange nebst den Zuschlägen, die zur Deckung der im vorläufigen Haushaltsplane
                festgestellten Ausgabensumme erforderlich sind, weiter erhoben werden.
            </p>
            <p>
                (4) Alle Streitigkeiten über Rechte und Ansprüche des bürgerlichen Rechtes und alle Strafsachen sind den
                bürgerlichen Gerichten zur Entscheidung zuzuweisen, soweit nicht durch diese Verfassung die Verfassung
                des Deutschen Reiches oder ein besonderes Gesetz etwas anderes angeordnet ist.
            </p>
            <p>
                (5) Der Staat nimmt in Sachen des Bürgerlichen Rechtes vor den ordentlichen Gerichten Recht.
            </p>
            <p>
                (6) Anhängige Streitsachen und strafrechtliche Untersuchungen können weder durch den Landtag noch durch
                die Ministerien oder sonstige Verwaltungsbehörden gehemmt, von den Gerichten erkannte Strafen können nur
                nach den Vorschriften dieser Verfassung oder der Verfassung des Deutschen Reiches gemildert oder
                erlassen werden.
            </p>
            <p>
                <strong>§ 70. </strong>(1) Über Ministeranklagen, Verfassungsbeschwerden und Verfassungsstreitigkeiten,
                für welche der Rechtsweg nicht geöffnet ist, entscheidet er Staatsgerichtshof.
            </p>
            <p>
                (2) Er besteht aus dem Präsidenten des Obersten Landesgerichtes als Vorsitzendem, aus acht Richtern,
                von denen drei dem Verwaltungsgerichtshof angehören müssen, und zehn Mitgliedern, die vom Landtage für
                seine Dauer mit Zweidrittelmehrheit der anwesenden Mitglieder zu wählen sind.
            </p>
            <p>
                (3) Die Richter des Verwaltungsgerichtshofes werden vom Präsidenten des Gerichtes, die übrigen Richter
                vom Präsidenten des Obersten Landesgerichts auf die Dauer ihres Hauptamtes ernannt.
            </p>
            <p>
                (4) Das Verfahren des Staatsgerichtshofes wird durch Gesetz geregelt.
            </p>
            <p>
                <strong>§ 71. </strong>(1) Für bestrittene Rechtsansprüche und Verbindlichkeiten auf dem Gebiete des
                öffentlichen Rechts steht nach Maßgabe der Gesetze das Verwaltungsrechtsverfahren offen.
            </p>
        </>
    )
}

export default Page;
