import * as React from 'react'

const Page = () => {
    return (
        <>
            <h3>Transkription zu:<br/>Flugblatt des Bayerischen Vereins für Frauenstimmrecht zu den Wahlen im Januar 1919</h3>
            <br/>
            <h3>Frauenwahlrecht wird zur Frauenwahlpflicht!</h3>
            <p>
                Frauen Bayerns, in wenigen Wochen sollt Ihr zur Wahl schreiten. Am 12. Januar zur Nationalversammlung
                für die bayerische Republik, am 16. Februar 1919 zur Nationalversammlung für die deutsche Republik.
            </p>
            <p>
                Seid Euch bewußt, daß diese Versammlungen den Grundstein legen für das zukünftige Zusammenleben der
                Staatsbürger in Bayern und in Deutschland.
            </p>
            <p>
                Noch ist Reaktion, noch ist Chauvinismus am Werk und [wird] versuchen, das schon Errungene und das
                noch zu Erstrebende zu zerstören.
            </p>
            <h3>
                    Frauen seid Euch bewußt, daß Ihr die neuen Ideale:<br/>Recht, Freiheit und Friede zu verteidigen habt!
            </h3>
            <p>
                Gebt nur solche Listen Eure Stimme, welche Namen von Personen enthalten, von denen Ihr genau wißt,
                daß sie für Völkerverständigung und Frauenstimmrecht eintreten, damit nie wieder ein Weltkrieg wie der
                letzte die Menschheit bedroht und damit wir Frauen die einmal gewonnene politische Freiheit nicht wieder
                verlieren.
            </p>
            <p>
                In wenigen Wochen muß nachgeholt werden, was in Jahren von vielen bayerischen und deutschen Frauen
                versäumt worden ist.
            </p>
            <p>
                Frauen Bayerns, unterrichtet Euch über die bestehenden politischen Parteien: Auskunft in allen
                politischen Angelegenheiten erteilt die Geschäftsstelle der unterzeichneten Vereine, Amalienstr. 27.
                Geöffnet täglich von 9–1 Uhr.
            </p>
            <h4>Bayerischer Verein für Frauenstimmrecht:</h4>
            <p>Dr. Anita Augspurg</p>

            <h4>Münchner Ortsgruppe d. Bayer. V. f. Frstr.:</h4>
            <p>Lida Gustava Heymann</p>

            <h4>Geschäftsstelle München des deutschen Frauenausschuß für dauernden Frieden:</h4>
            <p>Gertrud Baer</p>

        </>
    )
}

export default Page;
